import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col>
            <p>
              Copyright © 2021 SaaS for Global Benefits Management – Alle Rechte
              vorbehalten.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/datenschutzerklärung">Datenschutzerklärung</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
